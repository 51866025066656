import React from "react";
import styled from "@emotion/styled";

import {
	pMega,
	pStandard,
	richText,
	fontRoslindaleDisplay,
	fontSurt,
} from "../../styles/typography";
import { container, spacing, screen, grid } from "../../styles/mixins";

const Container = styled.div`
	${container};
	${spacing("marginTop", "xl")};
	${spacing("marginBottom", "xl")};
	justify-items: center;
	position: relative;

	/* ${(props) =>
		!props.noDots &&
		`
		&::before,
		&::after {
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			background-color: var(--fg);
			border-radius: 50%;
			grid-column: 7 / -7;
		}
	`}
	&::before {
		${(props) => !props.noDots && spacing("marginBottom", "m")};
	}
	&::after {
		${(props) => !props.noDots && spacing("marginTop", "m")};
	} */
	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	${richText};
	text-align: center;
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 10 / -10;
		${grid({ cols: 32 })}
	}

	@media ${screen("mx")} {
		grid-column: 14 / -14;
		${grid({ cols: 22 })}
	}

	p,
	blockquote {
		${pMega};
		grid-column: 1 / -1;
	}

	span {
		${fontSurt};
		font-size: 1.02em;
	}
`;

const Attribution = styled.cite`
	display: inline-block;
	font-style: normal;
	${spacing("marginTop", "l")};
	${pStandard};

	@media ${screen("ml")} {
		grid-column: 7 / -7;
	}

	@media ${screen("mx")} {
		grid-column: 9 / -9;
	}

	span {
		${fontRoslindaleDisplay};
		font-style: italic;
		text-transform: lowercase;
	}
`;

export default function MegaQuote({ content, attribution, noDots }) {
	return (
		<Container noDots={noDots}>
			<Content>
				<blockquote dangerouslySetInnerHTML={{ __html: content }} />
				{attribution && (
					<Attribution dangerouslySetInnerHTML={{ __html: attribution }} />
				)}
			</Content>
		</Container>
	);
}
