import React, { useEffect, useRef } from "react";
import SectionBody from "../SectionBody";
import SectionTitle from "../Structure/SectionTitle";
import styled from "@emotion/styled";

import { getTheme, spacing } from "../../styles/mixins";
import { useUIState } from "../../contexts/UIStore";

const Section = styled.div`
	${spacing("paddingBottom", "xl")};
	--fg: white;
	--bg: black;
	background-color: var(--bg);
	color: var(--fg);
	position: relative;
`;

const Wrapper = styled.div`
	position: relative;
	z-index: 4;
`;

export default function Dark({
	body,
	title,
	titleGutter,
	subtitle,
	components,
	keyId,
	menuTitle,
}) {
	const uiState = useUIState();
	const ref = useRef();
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						uiState.setSection(menuTitle.toUpperCase());
						uiState.addTheme(getTheme("dark"), keyId);
					} else {
						uiState.removeTheme(keyId);
					}
				});
			},
			{ rootMargin: "-50% 0px -50%" }
		);
		observer.observe(ref.current);
		return () => {
			observer.disconnect();
		};
	}, [menuTitle, uiState, keyId]);
	return (
		<Section ref={ref}>
			<Wrapper>
				<SectionTitle
					title={title}
					subtitle={subtitle}
					titleGutter={titleGutter}
					id={keyId}
				/>
				<SectionBody body={body} components={components} />
			</Wrapper>
		</Section>
	);
}
