import React from "react";
import styled from "@emotion/styled";

import { richText, pLead, fontSurt } from "../../styles/typography";
import { container, spacing, screen } from "../../styles/mixins";

const Container = styled.div`
	${container};
	${spacing("marginTop", "l")};
	${spacing("marginBottom", "l")};
	position: relative;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	${richText};
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 9 / -9;
	}

	@media ${screen("ml")} {
		grid-column: 10 / -10;
	}

	@media ${screen("mx")} {
		grid-column: 14 / -14;
	}

	p {
		${pLead};
		text-align: center;

		span {
			${fontSurt};
			font-size: 0.99em;
		}
	}
`;

export default function LeadParagraph({ content }) {
	return (
		<Container>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
		</Container>
	);
}
