import React, { memo, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { easing } from "../../../../util/math";
import {
	useViewportScroll,
	motion,
	useTransform,
	useSpring,
} from "framer-motion";
import cardFrontImage from "../../../../images/card-front-flipped.png";
import cardBackImage from "../../../../images/card-back.png";

const rotationZPoints = (rotation) => {
	switch (rotation) {
		case "bottom":
			return 4;
		case "right":
			return 2;
		default:
			return 0;
	}
};

const zPoints = (positionX, positionY, positionZ, rotation) => {
	return (
		Math.ceil(positionY * 10) -
		Math.ceil(positionX * 8) -
		Math.ceil(positionZ * 6) +
		rotationZPoints(rotation)
	);
};

const Wrapper = styled.div`
	position: absolute;
	width: ${(props) => props.width}px;
	padding-bottom: ${(props) => props.width * 1.6}px;
	top: 0;
	left: 0;
	transform-style: preserve-3d;
	transform: ${(props) =>
		`translateZ(${
			zPoints(
				props.positionX ?? 0,
				props.positionY ?? 0,
				props.positionZ ?? 0,
				props.rotation
			) * props.width
		}px) rotateX(-30deg) rotateY(45deg) rotateZ(0deg)`};

	${(props) =>
		!isNaN(props.positionX) &&
		!isNaN(props.positionY) &&
		!isNaN(props.positionZ) &&
		css`
			top: calc(
				50% -
					${`${
						(props.positionX + props.positionZ) * 18 * 0.02 * props.width
					}px`} +
					${`${
						props.rotation === "bottom"
							? -props.width * 1.6 * 0.5 - 0.08 * props.width
							: 0
					}px`} + ${`${(props.positionY - 1) * -props.width * 1.6 * 0.9}px`} +
					${`${props.angle === "back" ? props.width * 0.006 : 0}px`} +
					${`${props.angle === "front" ? props.width * 0.366 : 0}px`}
			);
			left: calc(
				36% +
					${`${
						(props.positionX - props.positionZ) * 8 * 0.0917 * props.width
					}px`} +
					${`${
						props.rotation === "right"
							? 4 * 0.0917 * props.width
							: -4 * 0.0917 * props.width
					}px`} + ${`${props.rotation === "bottom" ? props.width * 0.4 : 0}px`} +
					${`${props.angle === "back" ? -props.width * 0.37 : 0}px`} +
					${`${props.angle === "front" ? props.width * 0.37 : 0}px`}
			);
			z-index: ${zPoints(
				props.positionX,
				props.positionY,
				props.positionZ,
				props.rotation
			)};
		`}
`;

const Transformed = styled(motion.div)`
	border-radius: 0.5em;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	will-change: transform;
	background-image: url(${cardFrontImage});
	background-size: cover;
	background-position: center center;
`;

const CardFront = styled.div`
	border-radius: 0.5em;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: white;
	background-image: url(${cardBackImage});
	background-size: cover;
	background-position: center center;
	transform: translateZ(1px);
`;

function Card({
	refTop,
	refBottom,
	windowHeight,
	positionX,
	positionY,
	positionZ,
	rotation,
	angle = "straight",
	speedY = 0,
	speedX = 0,
	rotationX = 0,
	rotationY = 0,
	rotationZ = 0,
	ease = null,
	delay = 0,
	faceIn,
}) {
	const [cardWidth, setCardWidth] = useState(150);
	const { scrollY } = useViewportScroll();

	const rotateXStartAngle = (rotation, angle) => {
		if (rotation === "bottom") {
			return 90;
		} else if (angle === "back") {
			return 40;
		} else if (angle === "front") {
			return -40;
		} else {
			return 0;
		}
	};

	const rotateYStartAngle = (rotation) => {
		const originalYRotation = faceIn ? 180 : 0;

		if (rotation === "left") {
			return originalYRotation + -90;
		} else {
			return originalYRotation;
		}
	};

	const cardProgressY = useSpring(
		useTransform(
			scrollY,
			[refTop + (refBottom - refTop) * delay, refBottom - windowHeight],
			[0, refBottom * speedY * 0.1],
			{
				ease: ease ? easing[ease] : easing.outQuad,
			}
		),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const cardProgressX = useSpring(
		useTransform(
			scrollY,
			[refTop + (refBottom - refTop) * delay, refBottom + windowHeight],
			[0, windowHeight * 0.15 * speedX * 0.1],
			{
				ease: ease ? easing[ease] : easing.outQuad,
			}
		),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const cardProgressRotateX = useSpring(
		useTransform(
			scrollY,
			[refTop + (refBottom - refTop) * delay, refBottom + windowHeight],
			[
				rotateXStartAngle(rotation, angle),
				rotateXStartAngle(rotation, angle) + 270 * rotationX * 0.1,
			],
			{
				ease: ease ? easing[ease] : easing.outQuad,
			}
		),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const cardProgressRotateY = useSpring(
		useTransform(
			scrollY,
			[refTop + (refBottom - refTop) * delay, refBottom + windowHeight],
			[
				rotateYStartAngle(rotation),
				rotateYStartAngle(rotation) + 270 * rotationY * 0.1,
			],
			{
				ease: ease ? easing[ease] : easing.outQuad,
			}
		),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	const cardProgressRotateZ = useSpring(
		useTransform(
			scrollY,
			[refTop + (refBottom - refTop) * delay, refBottom + windowHeight],
			[0, 270 * rotationZ * 0.1],
			{
				ease: ease ? easing[ease] : easing.outQuad,
			}
		),
		{
			stiffness: 400,
			damping: 50,
		}
	);

	useEffect(() => {
		const handleResize = () => {
			updateCardWidth();
		};

		const updateCardWidth = () => {
			setCardWidth(
				window.innerWidth < 1024
					? window.innerWidth * 0.33
					: window.innerWidth * 0.15
			);
		};

		updateCardWidth();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Wrapper
			width={cardWidth}
			positionY={positionY}
			positionX={positionX}
			positionZ={positionZ}
			rotation={rotation}
			angle={angle}>
			<Transformed
				style={{
					y: speedY ? cardProgressY : 0,
					x: speedX ? cardProgressX : 0,
					rotateX: rotationX
						? cardProgressRotateX
						: rotateXStartAngle(rotation, angle),
					rotateY: rotationY
						? cardProgressRotateY
						: rotateYStartAngle(rotation),
					rotateZ: rotationZ ? cardProgressRotateZ : 0,
				}}>
				<CardFront />
			</Transformed>
		</Wrapper>
	);
}
export default memo(Card);
