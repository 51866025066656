import React from "react";
import SectionBody from "../SectionBody";
import styled from "@emotion/styled";

import { spacing } from "../../styles/mixins";
import { useGetTheme } from "../../contexts/ThemingContext";

const Section = styled.div`
	/* ${spacing("paddingTop", "xl")};
	${spacing("paddingBottom", "xl")}; */
	--fg: "black";
	color: var(--fg);
	transition: color 400ms linear;
	& + & {
		padding-top: 0;
	}
`;

export default function Home({ body, components }) {
	const theme = useGetTheme();
	return (
		<Section theme={theme}>
			<SectionBody body={body} components={components} />
		</Section>
	);
}
