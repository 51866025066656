import React from "react";
import styled from "@emotion/styled";

import { richText } from "../../styles/typography";
import { container, spacing, screen } from "../../styles/mixins";
import { useDefinitionModal } from "../../contexts/DefinitionModalContext";

const Container = styled.div`
	${container};
	${spacing("marginTop", "s")};
	${spacing("marginBottom", "s")};
	//position: relative;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
	z-index: 2;
`;

const Content = styled.div`
	${richText};
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 9 / -9;
	}

	@media ${screen("ml")} {
		grid-column: 14 / -14;
	}

	@media ${screen("mx")} {
		grid-column: 18 / -18;
	}
`;

export default function BodyCopy({ content, definitions }) {
	const { setContentKey, setActive } = useDefinitionModal();
	return (
		<Container
			onClick={(e) => {
				let target = e.target;
				if (target && target.classList.contains("definition-link")) {
					let key = target.dataset.key;
					setContentKey(key);
					setActive(true);
				}
			}}>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
		</Container>
	);
}
