import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "@emotion/styled";

import {
	fontRoslindaleDisplay,
	pEmphasis,
	richText,
} from "../../styles/typography";
import { container, spacing, screen, grid } from "../../styles/mixins";
import useResizeObserver from "use-resize-observer";

const Container = styled.div`
	${container};
	align-items: center;
	${spacing("marginTop", "s")};
	${spacing("marginBottom", "s")};
	position: relative;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Wrapper = styled.div`
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 4 / -4;
		${grid({ cols: 42 })}
	}

	@media ${screen("ml")} {
		grid-column: 14 / -5;
		display: block;
	}

	@media ${screen("mx")} {
		grid-column: 18 / -6;
	}
`;

const Content = styled.div`
	${richText};

	@media ${screen("md")} {
		grid-column: 6 / -6;
	}

	@media ${screen("ml")} {
		width: ${(22 / 31) * 100}%;
	}

	@media ${screen("mx")} {
		width: ${(14 / 26) * 100}%;
	}
`;

const Quote = styled.div`
	${richText};
	width: 100%;
	${spacing("paddingTop", "xl")};
	${spacing("paddingBottom", "xl")};
	grid-column: 1 / -1;
	text-align: center;

	@media ${screen("ml")} {
		float: right;
		clear: both;
		width: ${(23 / 31) * 100}%;
		height: 100%;
		padding-left: calc(100vw / 48);
		${spacing("paddingTop", "m")};
		${spacing("paddingBottom", "m")};
		text-align: left;
	}

	@media ${screen("mx")} {
		width: ${(20 / 26) * 100}%;
	}

	p,
	blockquote {
		${pEmphasis}
	}

	span {
		${fontRoslindaleDisplay};
		font-size: 1.02em;
	}
`;

const Spacer = styled.div`
	display: none;
	float: right;
	height: ${({ height }) => `${height}px`};
	clear: both;

	@media ${screen("ml")} {
		display: block;
	}
`;

export default function BreakoutQuote({ content, quote }) {
	const { ref: resizeRef, width, height } = useResizeObserver();
	const [spacerHeight, setSpacerHeight] = useState(0);
	const wrapperRef = useRef();
	const quoteRef = useRef();

	const setRefs = useCallback(
		(node) => {
			quoteRef.current = node;
			resizeRef(node);
		},
		[resizeRef]
	);

	useEffect(() => {
		const wrapperHeight = wrapperRef.current.getBoundingClientRect().height;
		const quoteHeight = quoteRef.current.getBoundingClientRect().height;
		const heightDifference = wrapperHeight - quoteHeight;
		setSpacerHeight(heightDifference * 0.5);
	}, [width, height]);

	return (
		<Container>
			<Wrapper ref={wrapperRef}>
				<Spacer height={spacerHeight} />
				<Quote ref={setRefs}>
					<blockquote dangerouslySetInnerHTML={{ __html: quote }} />
				</Quote>
				<Content dangerouslySetInnerHTML={{ __html: content }} />
			</Wrapper>
		</Container>
	);
}
