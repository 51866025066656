import React, { useEffect, useRef } from "react";
import SectionBody from "../SectionBody";
import SectionTitle from "../Structure/SectionTitle";
import styled from "@emotion/styled";
import { getTheme, spacing } from "../../styles/mixins";
import CardsParallaxBg from "../Feature/ActOne/CardsParallaxBg";
import { useUIState } from "../../contexts/UIStore";

const Section = styled.div`
	${spacing("paddingBottom", "xl")};
	--fg: black;
	color: var(--fg);
	position: relative;

	& + & {
		padding-top: 0;
	}
`;

export default function Part1({
	body,
	title,
	titleGutter,
	subtitle,
	components,
	keyId,
	menuTitle,
}) {
	const uiState = useUIState();
	const ref = useRef();
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						uiState.setSection(menuTitle.toUpperCase());
						uiState.addTheme(getTheme("light"), keyId);
					} else {
						uiState.removeTheme(keyId);
					}
				});
			},
			{ rootMargin: "-50% 0px -50%" }
		);
		observer.observe(ref.current);
		return () => {
			observer.disconnect();
		};
	}, [menuTitle, uiState, keyId]);

	return (
		<Section ref={ref}>
			<CardsParallaxBg />
			<SectionTitle
				title={title}
				subtitle={subtitle}
				titleGutter={titleGutter}
				id={keyId}
			/>
			<SectionBody body={body} components={components} />
		</Section>
	);
}
