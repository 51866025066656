import React from "react";
import styled from "@emotion/styled";

import {
	pEmphasis,
	pStandard,
	richText,
	fontRoslindaleDisplay,
} from "../../styles/typography";
import { container, spacing, screen, grid } from "../../styles/mixins";

const Container = styled.div`
	${container};
	${spacing("marginTop", "xl")};
	${spacing("marginBottom", "xl")};
	justify-items: center;
	position: relative;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	${richText};
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 10 / -10;
		${grid(30)}
	}

	@media ${screen("mx")} {
		grid-column: 14 / -14;
		${grid(22)}
	}

	p,
	blockquote {
		${pEmphasis};
		grid-column: 1 / -1;
	}

	span {
		${fontRoslindaleDisplay};
		font-size: 1.02em;
	}
`;

const Attribution = styled.cite`
	display: inline-block;
	font-style: normal;
	${spacing("marginTop", "l")};
	${pStandard};

	@media ${screen("ml")} {
		grid-column: 7 / -7;
	}

	@media ${screen("mx")} {
		grid-column: 9 / -9;
	}

	span {
		${fontRoslindaleDisplay};
		font-style: italic;
		text-transform: lowercase;
	}
`;

export default function AlignedQuote({ content, attribution }) {
	return (
		<Container>
			<Content>
				<blockquote dangerouslySetInnerHTML={{ __html: content }} />
				{attribution && (
					<Attribution dangerouslySetInnerHTML={{ __html: attribution }} />
				)}
			</Content>
		</Container>
	);
}
