import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useGetResizer } from "../../../contexts/Resizer";
import { screen, container } from "../../../styles/mixins";
import Card from "./Cover/Card";

const CardsContainer = styled.div`
	${container};
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: -1;

	@media ${screen("ml")} {
		display: grid;
	}
`;

const CardsWrapper = styled.div`
	grid-column: 3 / -3;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@media ${screen("md")} {
		grid-column: 9 / -9;
	}

	@media ${screen("ml")} {
		grid-column: 10 / -10;
	}

	@media ${screen("mx")} {
		grid-column: 14 / -14;
	}
`;

const CardTemplate = styled.div`
	position: absolute;
	width: 33vw;

	@media ${screen("ml")} {
		width: 15vw;
	}
`;

const Card1 = styled(CardTemplate)`
	top: 0;
	left: 100%;
	transform: translateX(50%);

	@media ${screen("mx")} {
		transform: translateX(100%);
	}
`;
const Card2 = styled(CardTemplate)`
	top: calc(58% - 0.58 * 400vh);
	left: 100%;
	transform: translateX(50%);

	@media ${screen("mx")} {
		transform: translateX(100%);
	}
`;
const Card3 = styled(CardTemplate)`
	top: calc(10% - 0.1 * 400vh);
	right: 100%;
	transform: translateX(-50%);

	@media ${screen("mx")} {
		transform: translateX(-100%);
	}
`;
const Card4 = styled(CardTemplate)`
	top: calc(42% - 0.38 * 400vh);
	right: 100%;
	transform: translateX(-50%);

	@media ${screen("mx")} {
		transform: translateX(-100%);
	}
`;
const Card5 = styled(CardTemplate)`
	top: calc(70% - 0.8 * 400vh);
	right: 100%;
	transform: translateX(-50%);

	@media ${screen("mx")} {
		transform: translateX(-100%);
	}
`;

export default function CardsParallaxBg() {
	const area = useGetResizer();
	const ref = useRef();
	const [refTop, setRefTop] = useState(0);
	const [refBottom, setRefBottom] = useState(1);
	const [windowHeight, setWindowHeight] = useState(768);

	useEffect(() => {
		const refPosition = ref.current?.getBoundingClientRect();
		const top = refPosition.top + window.pageYOffset;
		const bottom = top + refPosition.height - window.innerHeight;
		setRefTop(top);
		setRefBottom(bottom);
	}, [area]);

	useEffect(() => {
		const handleResize = () => {
			updateWindowHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return (
		<CardsContainer ref={ref}>
			<CardsWrapper>
				<Card1>
					<Card
						refTop={refTop - windowHeight * 2}
						refBottom={refBottom}
						windowHeight={windowHeight}
						rotation="right"
						speedY={3}
						rotationX={9}
						rotationY={50}
						rotationZ={5}
						ease="linear"
					/>
				</Card1>
				<Card2>
					<Card
						refTop={refTop - windowHeight * 2}
						refBottom={refBottom}
						windowHeight={windowHeight}
						rotation="right"
						speedY={2}
						rotationX={15}
						rotationY={45}
						ease="linear"
					/>
				</Card2>
				<Card3>
					<Card
						refTop={refTop - windowHeight}
						refBottom={refBottom}
						windowHeight={windowHeight}
						rotation="left"
						speedY={3}
						rotationX={10}
						rotationZ={-10}
						rotationY={-45}
						facingIn={true}
						ease="linear"
					/>
				</Card3>
				<Card4>
					<Card
						refTop={refTop - windowHeight}
						refBottom={refBottom}
						windowHeight={windowHeight}
						rotation="left"
						speedY={2}
						rotationX={10}
						rotationY={50}
						ease="linear"
					/>
				</Card4>
				<Card5>
					<Card
						refTop={refTop - windowHeight}
						refBottom={refBottom}
						windowHeight={windowHeight}
						rotation="left"
						speedY={3}
						rotationY={-25}
						rotationZ={-2.5}
						facingIn={true}
						ease="linear"
					/>
				</Card5>
			</CardsWrapper>
		</CardsContainer>
	);
}
