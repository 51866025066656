import React, { memo } from "react";
export default memo(function SectionBody({ body, components }) {
	return (
		<div>
			{body.map(({ type, ...props }, i) => {
				let Component = components[type];
				return Component ? <Component {...props} key={i} /> : null;
			})}
		</div>
	);
});
