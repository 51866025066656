import React from "react";
import styled from "@emotion/styled";

import { fontRoslindaleDisplay, richText } from "../../styles/typography";
import { container, spacing, screen } from "../../styles/mixins";
import { useUIState } from "../../contexts/UIStore";
import { observer } from "mobx-react-lite";

const Container = styled.div`
	${container};
	${spacing("marginBottom", "xl")};
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	${richText};
	grid-column: 3 / -3;
	text-align: center;

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 9 / -9;
		grid-column: ${({ titleGutter }) =>
			`${titleGutter + 1} / ${-1 * (titleGutter + 1)}`};
	}

	@media ${screen("mx")} {
		grid-column: 9 / -9;
	}

	.p-small {
		text-transform: uppercase;
		font-weight: 600;
		${spacing("marginBottom", "xl")};

		span {
			${fontRoslindaleDisplay};
			font-style: italic;
			text-transform: lowercase;
			font-weight: 400;
			font-size: 1.02em;
		}
	}
	.h2 {
		span {
			${fontRoslindaleDisplay};
			font-style: italic;
			text-transform: lowercase;
			font-weight: 500;
			font-size: 1.02em;
		}
	}
`;

const SectionTitle = observer(({ title, titleGutter = 8, subtitle, id }) => {
	const uiState = useUIState();
	return (
		<Container>
			<Content titleGutter={titleGutter}>
				<p className="p-small">
					{subtitle} <span>of</span> 08
				</p>
				<h2
					className="h2"
					dangerouslySetInnerHTML={{ __html: title }}
					id={id}
					style={{ scrollMarginTop: `${uiState.navHeight + 20}px` }}
				/>
			</Content>
		</Container>
	);
});
export default SectionTitle;
